import Vue from "vue";
import UserService from "@/services/user.js";
import AuthService from "@/services/auth.js";
import SettingsService from "@/services/settings.js";
import ContractService from "@/services/contract.js";
import TermsOfServiceService from "@/services/terms-of-service.js";
import VueCookies from "vue-cookies";
import utils from "@/plugins/utils.js";
import {alertInfoConst} from "@/assets/constants";

const initQuery = () => ({
  contrat_id: "",
  query_string: "",
  query_connection: {
    checked: "all",
    disabled: false
  },
  query_alarm: false,
  query_ids: null,
  query_process_area: {
    id: ""
  },
  query_show_stats: true,
  query_filtered_nodes: {
    connector: {},
    device: {},
    show: false,
    sid: 0
  },
  searchable_table: {}
});

/*
  Simple crud for local storage
  get    : localDB = (id)
  save   : localDB = (id, payload)
  remove : localDB = (id, null)
*/
export const localDB = (key, payload) => {
  if (payload && typeof payload == "object") {
    localStorage.setItem(key, JSON.stringify(payload));
    return payload;
  } else if (typeof payload == "undefined") {
    let dft = initQuery();
    let item = localStorage.getItem(key);
    if (item || typeof item == "string") {
      return {...dft, ...JSON.parse(item)};
    }
    return dft;
  } else if (payload === null) {
    localStorage.removeItem(key);
    return null;
  }
};

Object.defineProperty(Vue.prototype, "$utils", {value: utils});

Vue.use(VueCookies);

/* FOR TESTING MODE ONLY set _mode="debug" */
const _mode = ""; // token expires in 2min and refresh is made every minute

// refresh the access token loop
const _safe_interval = 5 * 60 * 1000; // safe interval (ms (5min) )
const _max_retries = 5; // number of retries
let _refresh_token_timeout = null;

function console_log(msg) {
  // should be wise to post it to an endpoint
  if (_mode == "debug") {
    //console.log(new Date()+" "+msg);
  }
}

function initialState() {
  let auth = new AuthService();
  return {
    isReady: false,
    isLoading: false,
    loginError: null,
    isRulesReady: false,
    users: null,
    loggedUser: null,
    loggedUserRole: null,
    user_interaction: true,
    token_max_retries: 0,
    settingsLoaded: false,
    authPolicies: null,
    contractUsage: null,
    terms_acceptance_datetime: null
  };
}

function calcInterval(ISODueDateTime) {
  let cfg = (Vue.http.options && Vue.http.options.config) || {};
  let safe_interval =
    (cfg ? cfg?.token_refresh?.safe_offset_interval : 0) || _safe_interval;
  if (_mode == "debug") {
    safe_interval = 1 * 60 * 1000;
  }
  let now = new Date().getTime();
  let interval = new Date(ISODueDateTime).getTime() - safe_interval - now;
  return interval;
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    INITIALIZE(state, payload) {
      Object.keys(initialState()).forEach((key) => {
        if (key in (payload || {})) {
          state[key] = payload[key];
        }
      });
    },
    RESET(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
      clearTimeout(_refresh_token_timeout);
      _refresh_token_timeout = null;
    },
    SET_USERS(state, option) {
      state.users = option;
    },
    SET_LOGGED_USER(state, user) {
      state.loggedUser = user;
    },
    SET_CONTRACT(state, contract) {
      if (state.loggedUser) {
        Vue.set(state.loggedUser, "contract", contract);

        let ix = state.loggedUser.is_member_contracts.findIndex(
          ({id}) => id == contract.id
        );

        if (ix != -1) {
          let memberContract = state.loggedUser.is_member_contracts[ix];
          memberContract.name = contract.name;
          memberContract.description = contract.description;
          Vue.set(state.loggedUser.is_member_contracts, ix, memberContract);
        }
      }
    },
    SET_TERMS_ACCEPTANCE_DATETIME(state, value) {
      state.terms_acceptance_datetime = value;
    },
    SET_LOGGED_USER_ROLE(state, role) {
      state.loggedUserRole = role;
    },
    IS_RULES_READY(state, val) {
      state.isRulesReady = val;
    },
    IS_READY(state, isReady) {
      state.isReady = isReady;
    },
    IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_LOGIN_ERROR(state, loginError) {
      state.loginError = loginError;
    },
    SET_USER_INTERACTION(state, value) {
      state.user_interaction = value;
    },
    SET_TOKEN_MAX_RETRIES(state, value) {
      state.token_max_retries = value;
    },
    SET_SETTINGS_LOADED(state, value) {
      state.settingsLoaded = value;
    },
    UPDATE_LOGGED_USER(state, value) {
      let user = JSON.parse(JSON.stringify(state.loggedUser));
      user = {...user, ...value};
      Vue.set(state, "loggedUser", user);
    },
    SET_AUTH_POLICIES(state, value) {
      state.authPolicies = value;
    },
    SET_CONTRACT_USAGE(state, usage) {
      state.contractUsage = usage;
    },
    RESET_USER_LIST(state) {
      state.users = null;
    }
  },
  actions: {
    // get the logged user
    async login(context, options) {
      var self = this;
      context.commit("IS_RULES_READY", false);
      context.commit("SET_LOGIN_ERROR", null);
      context.commit("IS_LOADING", true);
      //=======================
      let payload =
        (options &&
          "email" in options &&
          "password" in options && {
            email: options.email,
            password: options.password,
            otp: options.otp
          }) ||
        null;
      let keep_alive = (options && options.keep_alive) || false;
      let auth = new AuthService();
      try {
        let user = await auth.login(payload, keep_alive);
        if (!user) {
          throw new Error("Invalid user.");
        }

        if (!user.user_profile?.contract || !user.is_member_contracts) {
          throw new Error("No contract.");
        }

        // user.allowed_permissions = [];
        if (!user?.allowed_permissions?.length) {
          throw new Error("No permissions allowed.");
        }

        let filtered_permissions = (user?.allowed_permissions || []).filter(
          (item) => item?.contract_id == user?.contract_id
        );

        if (!filtered_permissions.length) {
          throw new Error("No contract permissions allowed.");
        }

        _refresh_token_timeout = null;

        // gets the logged user contract
        let contract = user.user_profile.contract;

        // is a root user?
        user.is_root = contract.owner_id == user.id;

        // does the user belongs to a system group?
        user.system_group = false;
        try {
          let procAreas =
            (
              (user?.allowed_permissions || []).find(
                ({contract_id}) => contract_id == contract.id
              ) || {}
            )?.process_areas || [];
          for (var i in procAreas || []) {
            for (var j in procAreas[i]?.user_groups || []) {
              if (procAreas[i].user_groups[j].is_system) {
                user.system_group = true;
              }
              if (user.system_group) break;
            }
            if (user.system_group) break;
          }
        } catch (e) {
          // old API version;
        }

        // Test only purposes - set the expiration to 2min from now
        if (_mode == "debug") {
          user.expiration_at = new Date(
            new Date().getTime() + 2 * 60 * 1000
          ).toISOString();
        }
        // end

        // begin: initialize portal data:
        let portal_data = user?.user_profile?.portal_data || {};
        portal_data.refreshInterval =
          portal_data.refreshInterval ||
          Vue.http.options.config?.dashboard?.refreshInterval ||
          30000;
        // portal_data.timezone =
        //   portal_data.timezone ||
        //   Intl.DateTimeFormat().resolvedOptions().timeZone;
        portal_data.datetimeFormat = portal_data.datetimeFormat || "L LTS";

        user.user_profile.portal_data = portal_data;
        // end: initialize portal data:

        context.dispatch("contractSetup", contract.id);

        context.commit("SET_LOGGED_USER", user);

        // Once logged always:
        // (1) load settings
        // (2) load contract features
        // (3) load user roles
        // (4) listen to user changed
        let query = {
          contract_id: contract.id
        };
        let srv = new SettingsService(); // 1
        srv.setup(query).then((response) => {
          context.commit("SET_SETTINGS_LOADED", true);
        });
        srv.updateUserPreferences(user);
        self.dispatch("user/configureUserContract", true); //2
        self.dispatch("user/configUserRoles"); //3
        self.dispatch("user/authMonitor"); //5
        if (!context.getters.authPolicies)
          self.dispatch("user/fetchAuthPolicies");

        document.addEventListener(
          "cookiechange",
          ({detail: {oldValue, newValue}}) => {
            let access_token = Vue.$cookies.get("access_token");
            if (!access_token) {
              self.dispatch("user/logout");
            }
          }
        );
      } catch (err) {
        context.commit("SET_LOGIN_ERROR", err);
        context.commit("IS_LOADING", false);
        throw new Error(err);
      }
    },

    setUser(context, user) {
      let self = this;
      // gets the logged user contract
      let contract = user.user_profile.contract;

      // is a root user?
      user.is_root = contract.owner_id == user.id;

      // does the user belongs to a system group?
      user.system_group = false;
      try {
        let procAreas =
          (
            (user?.allowed_permissions || []).find(
              ({contract_id}) => contract_id == contract.id
            ) || {}
          )?.process_areas || [];
        for (var i in procAreas || []) {
          for (var j in procAreas[i]?.user_groups || []) {
            if (procAreas[i].user_groups[j].is_system) {
              user.system_group = true;
            }
            if (user.system_group) break;
          }
          if (user.system_group) break;
        }
      } catch (e) {
        // old API version;
      }

      // Test only purposes - set the expiration to 2min from now
      if (_mode == "debug") {
        user.expiration_at = new Date(
          new Date().getTime() + 2 * 60 * 1000
        ).toISOString();
      }
      // end

      // begin: initialize portal data:
      let portal_data = user?.user_profile?.portal_data || {};
      portal_data.refreshInterval =
        portal_data.refreshInterval ||
        Vue.http.options.config?.dashboard?.refreshInterval ||
        30000;
      // portal_data.timezone =
      //   portal_data.timezone ||
      //   Intl.DateTimeFormat().resolvedOptions().timeZone;
      portal_data.datetimeFormat = portal_data.datetimeFormat || "L LTS";

      user.user_profile.portal_data = portal_data;
      // end: initialize portal data:

      context.dispatch("contractSetup", contract.id);

      context.commit("SET_LOGGED_USER", user);

      // Once logged always:
      // (1) load settings
      // (2) load contract features
      // (3) load user roles
      // (4) listen to user changed
      let query = {
        contract_id: contract.id
      };
      let srv = new SettingsService(); // 1
      srv.setup(query).then((response) => {
        context.commit("SET_SETTINGS_LOADED", true);
      });
      srv.updateUserPreferences(user);
      self.dispatch("user/configureUserContract", true); //2
      self.dispatch("user/configUserRoles"); //3
      self.dispatch("user/authMonitor"); //5
      if (!context.getters.authPolicies)
        self.dispatch("user/fetchAuthPolicies");

      // document.addEventListener(
      //   "cookiechange",
      //   ({detail: {oldValue, newValue}}) => {
      //     let access_token = Vue.$cookies.get("access_token");
      //     if (!access_token) {
      //       self.dispatch("user/logout");
      //     }
      //   }
      // );
    },

    // reset object to its initial state
    logout(context) {
      context.dispatch("clearUserDependencies");
      new AuthService().logout();
      context.commit("RESET");
    },

    // whenever user is interacting
    interaction(context, value) {
      context.commit("SET_USER_INTERACTION", value);
      context.dispatch("autoLogoff");
    },

    // validates the user session duedate and initialize refresh token monitor
    authMonitor(context) {
      let user = context.state.loggedUser;
      if (!user) return;
      let cfg = (Vue.http.options && Vue.http.options.config) || {};
      context.commit(
        "SET_TOKEN_MAX_RETRIES",
        (cfg && cfg?.token_refresh?.max_retries) || _max_retries
      );
      context.dispatch("refreshToken");
    },

    getAccessToken({state, commit}, interval) {
      console_log("Refreshing access token");
      let self = this;
      let user = state.loggedUser;
      let auth = new AuthService();
      let payload = {token: user.token};
      auth.refreshToken(payload).then((response) => {
        if (!state.loggedUser) {
          return;
        }
        let cfg = (Vue.http.options && Vue.http.options.config) || {};
        let max_retries =
          (cfg && cfg?.token_refresh?.max_retries) || _max_retries;
        let retry_interval = interval / max_retries;
        if (response) {
          console_log("Access token refreshed");
          user.token = response.token;
          user.expiration_at = response.expiration_at;
          user.refresh_expiration_at = response.refresh_expiration_at;
          // debug mode
          if (_mode == "debug") {
            user.expiration_at = new Date(
              new Date().getTime() + 2 * 60 * 1000
            ).toISOString();
          }
          // end
          commit("SET_TOKEN_MAX_RETRIES", max_retries); // restore it to the maximum
          commit("SET_LOGGED_USER", user);
          self.dispatch("user/refreshToken");
        } else {
          let attempts = state.token_max_retries - 1;
          console_log(
            `Access token could not be refreshed - attempt ${max_retries -
              attempts}`
          );
          if (attempts < 0) {
            // max number of attempts exceeded
            self.dispatch("user/logout");
          } else {
            // max number of attempts exceeded
            commit("SET_TOKEN_MAX_RETRIES", attempts);
            setTimeout(() => {
              self.dispatch("user/getAccessToken", interval);
            }, retry_interval);
          }
        }
      });
    },

    // reload the access token
    refreshToken({state, commit}) {
      let self = this;
      if (_refresh_token_timeout) return;
      let user = state.loggedUser;
      if (!user) return;
      // minutes before the token expiration
      let interval = calcInterval(user.expiration_at);
      // validate if it is already about to refresh it
      interval = interval > 1 ? interval : 1;
      _refresh_token_timeout = setTimeout(function() {
        if (!_refresh_token_timeout || !state.loggedUser) {
          // user might be logged out
          return;
        }
        clearTimeout(_refresh_token_timeout);
        _refresh_token_timeout = null;
        // before proceed, it validates user session due_datetime, since it might be possible that access_token can not be refreshed
        let authDueDate =
          new Date(user.refresh_expiration_at).getTime() - _safe_interval; // default 5 minutes before 24h after last token
        if (new Date().getTime() > authDueDate) {
          self.dispatch("user/logout");
          return;
        }
        // it can safely refreshs the access token now
        let auth = new AuthService();
        if (auth.keepAlive() || state.user_interaction) {
          self.dispatch("user/getAccessToken", interval);
        } else {
          // token timeout with no user interaction or keep alive is false
          self.dispatch("user/logout");
        }
      }, interval);
    },
    // logoff user if away for specified time
    autoLogoff({dispatch, state}) {
      if (
        state.authPolicies?.auto_logout_after_hours > 0 &&
        !state.user_interaction
      ) {
        dispatch("logout");
      }
    },
    // load user list
    fetchUsers({state, commit, rootState}, force) {
      var self = this;
      if (
        !(state.loggedUser && "contract_id" in state.loggedUser) ||
        (state.users instanceof Array && !force)
      )
        return;
      let srv = new UserService();
      let query = {
        contract_id: state.loggedUser.contract_id
      };
      commit("IS_READY", false);
      return srv.fetch(query).then((data) => {
        // if (data && data.length) {
        commit("SET_USERS", data);
        // }
        commit("IS_READY", true);
      });
    },

    // contract resources available for this user.
    configureUserContract(context, firstTime) {
      return new Promise((resolve) => {
        let user = context.state.loggedUser;
        if (!user) {
          resolve();
          return;
        }
        let srv = new ContractService();
        srv.get(user.contract_id).then((contract) => {
          if (contract) {
            // TODO: REMOVE IT
            // begin test
            //contract.pricing_by_data_range = false;
            // contract.is_yearly = true;
            // end test
            context.commit("SET_CONTRACT", contract);
            if (firstTime && contract?.portal_data?.scripts) {
              context.dispatch("scripts/fetchScriptContent", null, {
                root: true
              });
            }
          }
          resolve(contract);
        });
      });
    },

    // contract resources available for this user.
    updateActiveContract({state, commit, rootState}, contract_id) {
      return new Promise((resolve, reject) => {
        var self = this;
        let user = state.loggedUser;
        if (!user) {
          reject(null);
          return;
        }
        let current_user_contract_id = user.user_profile.contract.id;
        // Since user might have no contract yet...
        // check if user is member of desired contract
        // let lst=(user.is_member_contracts||[]).filter(function(i){
        //   return (i.id==contract_id);
        // })
        // if (!lst.length) return;
        // update it
        let srv = new UserService();
        let payload = {
          etag: user.etag,
          user_profile: {
            contract_id: contract_id
          }
        };
        srv
          .patch(user.id, payload)
          .then((response) => {
            if (
              response &&
              "user_profile" in response &&
              response.user_profile &&
              "etag" in response
            ) {
              if (
                current_user_contract_id == response.user_profile.contract.id
              ) {
                // if contract has not been changed (plan upgrade) keep on same page
                user.etag = response.etag;
                user.contract_id = response.user_profile.contract.id;
                user.is_root =
                  response.user_profile.contract.owner_id == user.id;
                user.user_profile = response.user_profile;
                commit("SET_LOGGED_USER", user);
                self.dispatch("user/configureUserContract"); // config contract
                self.dispatch("user/configUserRoles"); // config roles
                resolve(user);
              } else {
                resolve(user);
                // if contract has been changed (switch contract) reload the page
                // document.location.reload();
              }
            } else {
              reject(null);
            }
          })
          .catch((e) => {
            reject(null);
          });
      });
    },

    updateProfile(context, entry) {
      return new Promise((resolve) => {
        let payload = {
          ...{
            first_name: context.state.loggedUser.first_name,
            last_name: context.state.loggedUser.last_name,
            etag: context.state.loggedUser.etag,
            id: context.state.loggedUser.id
          },
          ...entry
        };
        let srv = new UserService();
        srv.updateProfile(payload).then((user) => {
          if (user) {
            context.commit("UPDATE_LOGGED_USER", user);
          }
          resolve(user);
        });
      });
    },

    // load user rules
    configUserRoles({state, commit, rootState}) {
      var self = this;
      let user = state.loggedUser;
      if (!user || !self._vm) {
        commit("IS_LOADING", false);
        return;
      }
      let utils = self._vm.$utils;
      let role = {
        rules: [],
        important: false
      };
      let subjects = [];
      for (let i in user.process_area) {
        let processarea = user.process_area[i];
        for (let j in processarea.user_groups) {
          let group = processarea.user_groups[j];
          for (let k in group.portal_permissions) {
            let subject = group.portal_permissions[k];
            let subjectName = utils.asKey(subject.name || "");
            if (subjects.indexOf(subjectName) == -1) {
              subjects.push(subjectName);
            }
          }
        }
      }
      if (subjects.length) {
        role.rules.push({actions: "manage", subject: subjects});
      }
      if ("$ability" in self._vm) {
        if (role.rules.length) {
          self._vm.$ability.update(role.rules);
        }
        commit("IS_RULES_READY", true);
      }
      commit("SET_LOGGED_USER_ROLE", role);
      commit("IS_LOADING", false);
    },

    updatePortalData(context, portalData) {
      return new Promise((resolve) => {
        let user = context.state.loggedUser;
        if (!user) {
          return;
        }
        let srv = new UserService();
        let payload = {
          etag: user.etag,
          user_profile: {
            portal_data: portalData
          }
        };
        srv
          .patch(user.id, payload)
          .then((response) => {
            if (
              response &&
              typeof response == "object" &&
              "user_profile" in response &&
              response.user_profile &&
              "etag" in response
            ) {
              user.etag = response.etag;
              user.user_profile.portal_data = response.user_profile.portal_data;
              context.commit("SET_LOGGED_USER", user);
              let settingsSrv = new SettingsService();
              settingsSrv.updateUserPreferences(user); // update dashboard
              resolve(user);
            } else {
              resolve(response);
            }
          })
          .catch((e) => resolve(e.message));
      });
    },
    async fetchAuthPolicies({commit, dispatch}) {
      let service = new AuthService();
      try {
        let policies = await service.fetchAuthPolicies();
        commit("SET_AUTH_POLICIES", policies);
      } catch (e) {
        throw new Error(e);
      }
    },
    updateContractPortalData({getters, commit}, portal_data) {
      return new Promise((resolve, reject) => {
        let service = new ContractService();
        let contract = getters.loggedUser.contract;
        let payload = {
          id: contract.id,
          etag: contract.etag,
          portal_data
        };
        service
          .updateContract(payload)
          .then((newContract) => {
            commit("SET_CONTRACT", newContract);
            resolve(newContract);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    async fetchContractUsage({commit, getters}) {
      let service = new ContractService();
      try {
        let usage = await service.getUsage(getters.contract_id);
        commit("SET_CONTRACT_USAGE", usage);
      } catch (e) {
        throw new Error(e);
      }
    },
    setTermsAcceptanceDatetime(context, payload) {
      context.commit("SET_TERMS_ACCEPTANCE_DATETIME", payload);
    },
    updateFeaturePreference({getters, dispatch}, feature) {
      if (!getters.loggedUser) return;
      let portalData = JSON.parse(
        JSON.stringify(getters.loggedUser.user_profile.portal_data ?? {})
      );
      if (!portalData.featureConfig) portalData.featureConfig = {};

      portalData.featureConfig[feature.name] = {...feature};
      delete portalData.featureConfig[feature.name].name;

      return dispatch("updatePortalData", portalData);
    },
    updateUsers({getters, commit}, payloads) {
      commit("IS_LOADING", true);
      let service = new UserService();
      let all = Promise.allSettled(
        payloads.map((payload) => service.save(payload))
      );
      return all.then((responses) => {
        let stateUsers = JSON.parse(JSON.stringify(getters.users));
        responses.forEach((response) => {
          if (response.status == "fulfilled") {
            let user = response.value;
            getters.users.forEach((u, i) => {
              if (u.id == user.id) {
                stateUsers[i] = user;
              }
            });
          }
        });
        commit("SET_USERS", stateUsers);
        commit("IS_LOADING", false);
        return responses;
      });
    },
    clearUserDependencies() {
      localStorage.removeItem("query");
    },
    clearContractDependencies() {
      localStorage.removeItem("tree_connector");
      localStorage.removeItem("tree_screen");
      localStorage.removeItem("tree_devices");
    },
    contractSetup(context, contractId) {
      if (!contractId) return;
      let cfg = localDB("query");
      if (cfg) {
        if (
          !cfg?.contract_id ||
          parseInt(cfg?.contract_id) !== parseInt(contractId)
        ) {
          cfg.contract_id = contractId;
          context.dispatch("clearContractDependencies");
          localDB("query", cfg);
        }
      }
    }
  },
  getters: {
    isReady(state) {
      return state.isReady;
    },
    users(state) {
      return state.users ?? [];
    },
    authPolicies(state) {
      return state.authPolicies;
    },
    isSettingsLoaded(state) {
      return state.settingsLoaded;
    },
    isLoading(state) {
      return state.isLoading;
    },
    loginError(state) {
      return state.loginError;
    },
    isLogged(state) {
      return !state.isLoading && !state.loginError && state.loggedUser != null;
    },
    basicProfile(state) {
      return {
        first_name: state.loggedUser?.first_name || "",
        last_name: state.loggedUser?.last_name || "",
        full_name:
          (state.loggedUser?.first_name || "") +
          " " +
          (state.loggedUser?.last_name || ""),
        name: state.loggedUser?.name || "",
        email: state.loggedUser?.email || ""
      };
    },
    isRulesReady(state) {
      return state.isRulesReady;
    },
    loggedUser(state) {
      return state.loggedUser;
    },
    loggedUserRole(state) {
      return state.loggedUserRole;
    },
    contract(state) {
      let contract = (state.loggedUser && state.loggedUser.contract) || null;
      if (contract) {
        // for testing purposes
        // contract.maximum_connectors = 3;
        // contract.registered_connectors = 3;
        // contract.maximum_histories = 3;
        // contract.maximum_data = 3;
        // contract.registered_histories = 3;
        // contract.registered_data = 3;
        // contract.maximum_alarms=3;
        // contract.registered_alarms=3;
      }
      return contract;
    },
    contract_id(state) {
      return (
        (state.loggedUser &&
          state.loggedUser.user_profile &&
          state.loggedUser.user_profile.contract &&
          state.loggedUser.user_profile.contract.id) ||
        null
      );
    },
    hasUserAccessTo(state) {
      return (processAreaId) => {
        return (
          state.loggedUser.is_root ||
          state.loggedUser.system_group ||
          !processAreaId ||
          (
            state.loggedUser?.allowed_permissions || []
          ).some(({process_areas}) =>
            process_areas.some(({id}) => id == processAreaId)
          )
        );
      };
    },
    contractUsage(state) {
      return state.contractUsage;
    },
    termsAcceptanceDatetime(state) {
      return state.terms_acceptance_datetime;
    },
    isAccountLocked(state, getters) {
      // return true; // for testing purposes, uncomment this line
      return getters?.contract?.alerts_info?.number == alertInfoConst.BLOCKED;
    }
  }
};
