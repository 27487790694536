import Vue from "vue";
import VueResource from "vue-resource";
import AuthService from "@/services/auth";
import { parseMessage } from "@/utils";

Vue.use(VueResource);

export default class ImageService {
  async fetch(params = {}) {
    let url = "images/";
    let auth = new AuthService();
    try {
      let response = await Vue.http.get(url, {
        params,
        ...auth.requestOptions()
      });
      if (response?.body) {
        return response.body;
      } else {
        return null;
      }
    } catch (e) {
      //console.error(e);
      throw new Error(
        e.message ||
          e.body?.detail ||
          parseMessage(e.body ?? {}) ||
          "Failed to fetch"
      );
    }
  }

  async get(id, params = {}) {
    let url = `images/${id}/`;
    let auth = new AuthService();
    try {
      let response = await Vue.http.get(url, {
        params,
        ...auth.requestOptions()
      });
      if (response?.body) {
        return response.body;
      } else {
        return null;
      }
    } catch (e) {
      throw new Error(
        e.message ||
          e.body?.detail ||
          parseMessage(e.body ?? {}) ||
          "Failed to fetch"
      );
    }
  }

  async save(payload) {
    let url = `images/`;
    let auth = new AuthService();
    let body = payload,
      id,
      etag,
      request;
    // convert payload to FormData if it's a object
    if (!(payload instanceof FormData) && typeof payload == "object") {
      body = new FormData();
      for (let [prop, value] of Object.entries(payload)) {
        body.append(prop, value);
      }
    }
    // get id and etag if provided
    if (body.has("id") && body.has("etag")) {
      id = body.get("id");
      etag = body.get("etag");
      body.delete("id");
      body.delete("etag");
    }
    // if id and etag was passed, use patch
    // otherwise use post
    if (id && etag) {
      request = Vue.http.patch(
        url + id + "/",
        body,
        auth.requestOptions({ "If-Match": etag })
      );
    } else {
      request = Vue.http.post(url, body, auth.requestOptions());
    }
    // await for request and handle result
    try {
      let response = await request;
      if (response?.body) {
        return response.body;
      } else {
        return null;
      }
    } catch (e) {
      throw new Error(
        e.message ||
          e.body?.detail ||
          parseMessage(e.body ?? {}) ||
          "Failed to fetch"
      );
    }
  }

  async remove(params, etg) {
    let id, etag;
    if (typeof params == "object") {
      ({ id, etag } = params);
    } else {
      id = params;
      etag = etg;
    }
    let url = `images/${id}/`;
    let auth = new AuthService();
    try {
      let response = await Vue.http.delete(
        url,
        auth.requestOptions({ "If-Match": etag })
      );
      if (response?.body) {
        return response.body;
      } else {
        return {id: id};
      }
    } catch (e) {
      throw new Error(
        e.message ||
          e.body?.detail ||
          parseMessage(e.body ?? {}) ||
          "Failed to fetch"
      );
    }
  }
}
